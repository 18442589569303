import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { setToNull } from '../store/slices/currentPage.js';

//*******Images ********/
import mail from '../images/mail.png'
import whatsapp from '../images/whatsapp.png'
import bear from '../images/bear.png'
import heroImg from '../images/mockup.png'
import consultation from '../images/consultation.png'
import design from '../images/design2.png'
import programming from '../images/programming.png'
import rocket from '../images/rocket.png'
import triangle from '../images/triangle.png'

import ghazalLebnanLogo from '../images/ghazalLebnanLogo.png'
import prototype5 from '../images/prototype5.png'

const Freelance = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const navigateToProject = (path) => {
        navigate(`/freelance/project/${path}`)
        dispatch(setToNull())
    }

    const handleWhatsAppClick = () => {
        const phoneNumber = '96569954663';
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:aliifarajj@hotmail.com';
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

    return ( 
        <main>
            <section className="hero hero-freelance">
                <div className="line line-1"></div>
                <div className="line line-2"></div>

                <div className="intro">
                    <h1>Unlock Your Online Potential with a Stunning Website!</h1>

                    <div className="social-media display-freelance">
                        <img src={whatsapp} alt="Whatsapp logo" onClick={handleWhatsAppClick}/>
                        <img src={mail} alt="Mail" onClick={handleEmailClick}/>
                    </div>

                </div>

                <figure>
                    <div className="hero-img hero-img-freelance">
                        <img src={heroImg} alt="Website" className='website-img'/>

                        <div className="nova">
                            <div className="color-1"></div>
                            <div className="color-2"></div>
                            <div className="color-3"></div>
                            <div className="color-4"></div>
                        </div>
        
                    </div>
                </figure>
            </section>

            <section className="about freelance-about">
                <h2>What I Offer?</h2>
                <p>I create beautiful, high-performance static websites tailored to
                     your unique needs. Whether you’re looking for a sleek portfolio,
                      a professional business site, or a personal blog, I craft static
                       sites that are visually stunning and fast-loading.
                </p>

                <div className="nova2"></div>

                <div class="orbit">
                    <div class="rotating-item">
                        <div class="sun"></div>
                        <div class="ball"></div>
                    </div>
                </div>
            </section>

            <section className="process">
                <h2>How Your Website is Built?</h2>
                <div className="website-steps">
                    <div className="step">
                        <img src={consultation} alt="" />
                        <div className="step-description">
                            <h3>Initial Consultation</h3>
                            <p>We'll start with a detailed consultation to understand your needs, goals, and preferences for the website.</p>
                        </div>
                    </div>

                    <div className="steps-lines">
                        <div className="one-line"></div>
                    </div>

                    <div className="step">
                    <img src={design} alt="Design a website" />
                        <div className="step-description">
                            <h3>Design Phase</h3>
                            <p>I will design the website layout and visual elements based on the needs. You'll have the opportunity to review and provide feedback on the design drafts.</p>
                        </div>
                    </div>

                    <div className="steps-lines">
                        <div className="one-line"></div>
                    </div>

                    <div className="step">
                        <img src={programming} alt="Programming" />
                        <div className="step-description">
                            <h3>Development Phase</h3>
                            <p>Once the design is approved, I'll start developing the website, turning the design into a functional site and adding features and content.</p>
                        </div>
                    </div>

                    <div className="steps-lines">
                        <div className="one-line"></div>
                    </div>

                    <div className="step">
                        <img src={rocket} alt="Rocket" />
                        <div className="step-description">
                            <h3>Launch</h3>
                            <p>After your final approval, I'll launch the website and perform a final check to ensure everything is working as expected.</p>
                        </div>
                    </div>
                </div>

                <div className="line line-1"></div>
                <div className="nova6">
                    <div style={{backgroundColor: "#FF00D4"}}></div>
                    <div style={{backgroundColor: "purple"}}></div>
                    <div style={{backgroundColor: "blue"}}></div>
                    <div style={{backgroundColor: "#8351D4"}}></div>
                </div>
            </section>

            <section className="work">
                <h2>Recent Projects</h2>
                
                <div className="work-container">
                    <div className="case-studies case-studies-freelance">
                        <div className="case-studies-container case-studies-container-freelance">
                            <div className="case-study yellow" onClick={()=>navigateToProject("ghazallebnan")}>
                                <img src={ghazalLebnanLogo} alt="Logo" className="a" style={{width: "120px", height: "80px"}}/>
                                <img src={prototype5} alt="app-prototype" className="b"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nova">
                    <div className="color-1"></div>
                    <div className="color-2"></div>
                    <div className="color-3"></div>
                    <div className="color-4"></div>
                </div>
            </section>

            <section className="c2a">
                <img src={triangle} alt="Stars triangle" className='stars-triangle'/>
                <h2>Get Your Own Website!</h2>
                <p>Contact me for further details</p>
                <div className="social-media display-freelance">
                    <img src={whatsapp} alt="Whatsapp logo" onClick={handleWhatsAppClick}/>
                    <img src={mail} alt="Mail" onClick={handleEmailClick}/>
                </div>
                <div className="nova6">
                    <div style={{backgroundColor: "#FF00D4"}}></div>
                    <div style={{backgroundColor: "purple"}}></div>
                    <div style={{backgroundColor: "blue"}}></div>
                    <div style={{backgroundColor: "#8351D4"}}></div>
                </div>
            </section>
        </main>
     );
}
 
export default Freelance;