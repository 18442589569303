import { Routes, Route } from 'react-router-dom'

//components
import Header from "./components/Header";
import Main from "./components/Main";
import Google from './components/Google';
import Meta from './components/Meta';
import Class101 from './components/Class101';
import Footer from "./components/Footer";
import Contact from './components/Contact';
import PageNotFound from './components/PageNotFound';
import About from './components/About';
import Freelance from './components/Freelance';
import FreelanceProject from './components/FreelanceProject';
import Sidebar from './components/Sidebar';

function App() {
  return (
    <div className="App">
      <Header/>
      <Sidebar/>
      
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/google-certificates" element={<Google/>}/>
        <Route path="/meta-certificates" element={<Meta/>}/>
        <Route path="/case-study/class101" element={<Class101/>}/>
        <Route path="/freelance" element={<Freelance/>}/>
        <Route path="/freelance/project/ghazallebnan" element={<FreelanceProject/>}/>
        <Route path="/*" element={<PageNotFound/>}/>
      </Routes>

      <Footer/>
    </div>
  );
}

export default App;
