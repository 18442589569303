import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const About = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    return ( 
        <main className="about-page">
            <h1>About Me</h1>
            <section className="about">
                <p >Welcome to my space! <br />
                I'm a software engineering graduate based in Kuwait, 
                I began my career studying software engineering and have 
                since gained a lot of knowledge and experience through numerous
                 university projects and various specialized courses.
                </p>

                <p>I specialize in creating seamless user experiences (UX)
                     and captivating user interfaces (UI) that translate into 
                     engaging and functional websites. With a strong foundation 
                     in software engineering, I design and develop websites that
                      not only look great but also prioritize usability and user 
                    satisfaction.
                </p>

                <div className="nova2"></div>

                <div class="orbit">
                    <div class="rotating-item">
                        <div class="sun"></div>
                        <div class="ball"></div>
                    </div>
                </div>
            </section>
        </main>
     );
}
 
export default About;