import yellowStar from '../images/star.png'
import uncoloredStar from '../images/star-grey.png'

const Skill = ({skill, skillImg, rate, color, keyframe}) => {

    const greyStars = 5 - rate;
    let starElements = [];

    for (let i = 0; i < rate; i++) {
        starElements.push(<img key={i} src={yellowStar} alt="Rating Star" />);
    }

    if(greyStars > 0){
        for (let i = 0; i < greyStars; i++) {
            starElements.push(<img key={i} src={uncoloredStar} alt="Rating Star" />);
        }
    }

    return ( 
        <div className="skill">
            <h3>{skill}</h3>
            <figure>
                <div className={`light ${keyframe}`} style={{backgroundColor: color}}></div>
                <img src={skillImg} alt="Skill"/>
            </figure>
            <div className="stars">
                {starElements}
            </div>
        </div>
     );
}
 
export default Skill;