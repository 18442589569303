import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Certificate from "./Certificate"
import certificate1 from "../images/certificate9.png"
import certificate2 from "../images/certificate10.png"
import certificate3 from "../images/certificate11.png"
import certificate4 from "../images/certificate12.png"
import certificate5 from "../images/certificate13.png"
import certificate6 from "../images/certificate14.png"
import certificate7 from "../images/certificate15.png"

const Meta = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return ( 
        <main className='certificates-page'>
            <h1>Frontend Meta Certifications</h1>
            <section className="google-certificates">
                <div className="certificates">
                    <Certificate img={certificate1} h3={"Introduction to Front-End Development"} p={"Covered foundational concepts of front-end development, including HTML, CSS, and basic JavaScript programming."} link={"https://www.coursera.org/account/accomplishments/verify/NV5BA52GCE3P"}/>
                    <Certificate img={certificate2} h3={"Programming with JavaScript"} p={"Developed proficiency in JavaScript programming, including essential syntax, functions, and DOM manipulation."} link={"https://www.coursera.org/account/accomplishments/verify/AFSFY43A3PNJ"}/>
                    <Certificate img={certificate3} h3={"Version Control"} p={"Acquired skills in using version control systems (e.g., Git) for collaborative software development and code management."} link={"https://www.coursera.org/account/accomplishments/verify/CV57HLKQ7HPZ"}/>
                    <Certificate img={certificate4} h3={"HTML and CSS in Depth"} p={"Explored advanced techniques and best practices in HTML and CSS to create responsive and visually appealing web interfaces."} link={"https://www.coursera.org/account/accomplishments/verify/7DST7GMEN4N9"}/>
                    <Certificate img={certificate5} h3={"React Basics"} p={"Mastered the fundamentals of React.js library, including component-based architecture and state management."} link={"https://www.coursera.org/account/accomplishments/verify/HZTBB3Y8LQC7"}/>
                    <Certificate img={certificate6} h3={"Advanced React"} p={"Delved deeper into React.js concepts, including hooks, context API, and advanced state management patterns."} link={"https://www.coursera.org/account/accomplishments/verify/9367JLWKWUSR"}/>
                    <Certificate img={certificate7} h3={"Principles of UX/UI Design"} p={"Studied fundamental principles of UX/UI design, focusing on user-centered design, wireframing, and prototyping."} link={"https://www.coursera.org/account/accomplishments/verify/QYFHUB3CYVAL"}/>
                </div>

                
                <div class="orbit" style={{marginTop: "50px", position: "static", zIndex: "1"}}>
                    <div class="rotating-item" style={{width: "200px", height: "200px"}}>
                        <div class="sun" style={{width: "50px", height: "50px"}}></div>
                        <div class="ball" style={{width: "15px", height: "15px",top: "-8px"}}></div>
                    </div>
                </div>
            </section>
        </main>
     );
}
 
export default Meta;