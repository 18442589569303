import { createSlice } from '@reduxjs/toolkit'

export const currentPage = createSlice({
  name: 'currentPage',
  initialState: {
    value: "home",
  },
  reducers: {
    setToNull: (state) => {
        state.value = ""
    },
    setToHome: (state) => {
        state.value = "home"
    },
    setToAbout: (state) => {
        state.value = "about"
    },
    setToFreelance: (state) => {
        state.value = "freelance"
    },
    setToContact: (state) => {
        state.value = "contact"
    },
  },
})

export const { setToNull, setToHome, setToAbout, setToFreelance, setToContact } = currentPage.actions

export default currentPage.reducer