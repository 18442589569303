import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import logo from '../images/ghazalLebnanLogo.png'
import prototype from '../images/prototype6.png'
import mail from '../images/mail.png'
import whatsapp from '../images/whatsapp.png'
import triangle from '../images/triangle.png'

import figma from '../images/figma.png'
import html from '../images/html.png'
import css from '../images/css.png'
import js from '../images/js.png'





const Class101 = () => {
    const { pathname } = useLocation();

    const navigateToSite = () => {
        window.open("https://www.ghazallebnan.com/", '_blank');
    }

    const handleWhatsAppClick = () => {
        const phoneNumber = '96569954663';
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:aliifarajj@hotmail.com';
    };


    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return ( 
        <main className='class101'>
            <div className="scroll-watcher"></div>
            <div className="app-logo">
                <figure>
                    <img src={logo} alt="GhazalLebnan-logo" id='ghazallebnan'/>
                </figure>
                <h1 style={{display: "none"}}>Ghazal Lebnan</h1>
            </div>
            <img src={prototype} alt="application-prototype" className='prototype'/>

            <section className="app-introduction">
                <div className="overview">
                    <h2>Overview</h2>
                    <div>
                        <h3>Project Type:</h3>
                        <p>Freelance Project</p>
                    </div>
                    <div>
                        <h3>Timeline:</h3>
                        <p>3 Days</p>
                    </div>
                    <div>
                        <h3>Tools Used:</h3>
                        <div className="tools-icons">
                            <img src={figma} alt="Figma" />
                            <img src={html} alt="HTML" />
                            <img src={css} alt="CSS" />
                            <img src={js} alt="JavaScript" />
                        </div>
                    </div>
                    <div className="btns">
                        <button className="btn primary-btn" onClick={navigateToSite}><a>Visit Website</a></button>
                    </div>
                </div>
                <div className="details">
                    <div className="introduction">
                        <h2>About The Project</h2>
                        <p>
                        In this project, I crafted a static website for a shop that sells a variety of fresh and preserved fruits, vegetables, dates, and foodstuffs. The design emphasizes a clean and inviting layout that effectively highlights the shop's diverse product offerings. Utilizing modern web technologies, I ensured the site is fast, responsive, and accessible on all devices. The intuitive navigation and organized content structure make it easy for visitors to explore products and find information quickly.</p>
                    </div>
                </div>
            </section>

            <section className="c2a" style={{marginBottom: "100px"}}>
                <img src={triangle} alt="Stars triangle" className='stars-triangle'/>
                <h2>Get Your Own Website!</h2>
                <p>Contact me for further details</p>
                <div className="social-media display-freelance">
                    <img src={whatsapp} alt="Whatsapp logo" onClick={handleWhatsAppClick}/>
                    <img src={mail} alt="Mail" onClick={handleEmailClick}/>
                </div>
                <div className="nova6">
                    <div style={{backgroundColor: "#FF00D4"}}></div>
                    <div style={{backgroundColor: "purple"}}></div>
                    <div style={{backgroundColor: "blue"}}></div>
                    <div style={{backgroundColor: "#8351D4"}}></div>
                </div>
            </section>


        </main>
     );
}
 
export default Class101;