const Certificate = ({img, h3, p, link}) => {

    const handleClick = () => {
        window.open(link, '_blank');
    }

    return ( 
        <div className="certificate">
            <img src={img} alt="Certificate" />
            <div className="info">
                <h3>{h3}</h3>
                <p>{p}</p>
                <button className="tertiary-btn" onClick={handleClick}><a>See Credentials</a></button>
            </div>
        </div>
     );
}
 
export default Certificate;