import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { setToNull, setToHome, setToAbout, setToFreelance, setToContact } from '../store/slices/currentPage.js';
import { setToOpen, setToClose } from '../store/slices/sidebar.js';


import logo from '../images/logo.png'
import cross from '../images/cross.png'

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const currentPage = useSelector((state) => state.currentPage.value)
    const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen)

    const navigateToHome = () => {
        navigate("/")
        dispatch(setToHome())
        dispatch(setToClose())
    }
    const navigateToAbout = () => {
        navigate("/about")
        dispatch(setToAbout())
        dispatch(setToClose())
    }
    const navigateToFreelance = () => {
        navigate("/freelance")
        dispatch(setToFreelance())
        dispatch(setToClose())
    }
    const navigateToContact = () => {
        navigate("/contact")
        dispatch(setToContact())
        dispatch(setToClose())
    }
    const handleDownload = () => {
        window.open(`${process.env.PUBLIC_URL}/Ali-Youssef-CV.pdf`, '_blank');
    };

    const closeSidebar = () => {
        dispatch(setToClose())
    }

    return ( 
        <div className={`sidebar ${isSidebarOpen ? 'show-sidebar' : ''}`}>
            <div className="nova3"></div>
            <img src={cross} alt="Close button" className='cross' onClick={closeSidebar}/>
            
            <nav>
                <ul>
                    <li className={`${currentPage === "home" && "current-page"}`} onClick={navigateToHome}>Home</li>
                    <li className={`${currentPage === "about" && "current-page"}`} onClick={navigateToAbout}>About</li>
                    <li className={`${currentPage === "freelance" && "current-page"}`} onClick={navigateToFreelance}>Freelance Work</li>
                    <li className={`${currentPage === "contact" && "current-page"}`} onClick={navigateToContact}>Contact</li>
                    <li onClick={handleDownload}>Resume</li>
                </ul>
            </nav>

            <img src={logo} alt="Logo" onClick={navigateToHome} className='logo'/>
        </div>
     );
}
 
export default Sidebar;