import logo from '../images/logo.png'

const Footer = () => {
    return ( 
        <footer>
            <img src={logo} alt="Logo" />
            <p>© 2024 Ali Youssef. All Rights Reserved</p>
        </footer>
     );
}
 
export default Footer;