import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setToNull, setToHome } from '../store/slices/currentPage.js';

const PageNotFound = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { pathname } = useLocation();

    const goToHome = () => {
        navigate("/")
        dispatch(setToHome())
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
      dispatch(setToNull())
    }, []);
    
    return ( 
        <main className="page-not-found">
            <h1><span>404</span><br />Page Not Found</h1>
            <div className="redirect">
                <p>Got Lost?!</p>
                <button className="primary-btn" onClick={goToHome}><a>Go to main page</a></button>
            </div>
            <div className="nova6">
                <div style={{backgroundColor: "#FF00D4"}}></div>
                <div style={{backgroundColor: "purple"}}></div>
                <div style={{backgroundColor: "blue"}}></div>
                <div style={{backgroundColor: "#8351D4"}}></div>
            </div>
            <div className="planet">
                <div className="planet-moon"></div>
            </div>
        </main>
     );
}
 
export default PageNotFound;