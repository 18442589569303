import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
    name: "openOrClose",
    initialState: {
        isSidebarOpen: false
    },
    reducers: {
        setToClose: (state) => {
            state.isSidebarOpen = false
        },
        setToOpen: (state) => {
            state.isSidebarOpen = true
        },
    },
})

export const { setToOpen, setToClose } = sidebarSlice.actions

export default sidebarSlice.reducer