import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Certificate from "./Certificate"
import certificate1 from "../images/certificate1.png"
import certificate2 from "../images/certificate2.png"
import certificate3 from "../images/certificate3.png"
import certificate4 from "../images/certificate4.png"
import certificate5 from "../images/certificate5.png"
import certificate6 from "../images/certificate6.png"
import certificate7 from "../images/certificate7.png"
import finalCertificate from "../images/certificate8.png"

const Google = () => {
    const { pathname } = useLocation();

    const handleClick = () => {
        window.open("https://coursera.org/verify/professional-cert/Q9WQ6YZ8Q5EM", '_blank');
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return ( 
        <main className='certificates-page'>
            <h1>Google UX Design Certification</h1>
            <section className="google-certificates">
                <div className="certificates">
                    <Certificate img={certificate1} h3={"Foundations of User Experience (UX) Design"} p={"Completed comprehensive course covering fundamental principles of UX design, including user research, wireframing, and prototyping."} link={"https://www.coursera.org/account/accomplishments/verify/EC23LJNGPHG5"}/>
                    <Certificate img={certificate5} h3={"Start the UX Design Process: Empathize, Define, and Ideate"} p={"Explored the initial phases of the UX design process, focusing on user empathy, problem definition, and ideation to drive innovative solutions."} link={"https://www.coursera.org/account/accomplishments/verify/SQE77YBSB3GC"}/>
                    <Certificate img={certificate2} h3={"Build Wireframes and Low-Fidelity Prototypes"} p={"Developed skills in creating wireframes and low-fidelity prototypes to visualize design concepts and iterate on UX/UI ideas."} link={"https://www.coursera.org/account/accomplishments/verify/CQY9VQEF227R"}/>
                    <Certificate img={certificate7} h3={"Conduct UX Research and Test Early Concepts"} p={"Proficient in conducting user research and usability testing to validate design concepts and iterate on UX/UI improvements."} link={"https://www.coursera.org/account/accomplishments/verify/TXQQKF6PXD98"}/>
                    <Certificate img={certificate3} h3={"Create High-Fidelity Designs and Prototypes in Figma"} p={"Advanced proficiency in Figma to design high-fidelity user interfaces and interactive prototypes for web and mobile applications."} link={"https://www.coursera.org/account/accomplishments/verify/VL3ZK5FQSQKH"}/>
                    <Certificate img={certificate6} h3={"Build Dynamic User Interfaces (UI) for Websites"} p={"Hands-on experience in crafting dynamic and engaging user interfaces (UI) for websites, emphasizing usability and aesthetic appeal."} link={"https://www.coursera.org/account/accomplishments/verify/UXV7JBAW4Y2W"}/>
                    <Certificate img={certificate4} h3={"Design a User Experience for Social Good & Prepare for Jobs"} p={"Applied UX design principles to create solutions for social impact, gaining insights into real-world UX challenges and job preparation."} link={"https://www.coursera.org/account/accomplishments/verify/NM277XFRUTHE"}/>
                </div>

                <div className="completion">
                    <img src={finalCertificate} alt="certificate-of-completion" />
                    <div className="info">
                        <h3>Certificate Of Completion</h3>
                        <button className="tertiary-btn" onClick={handleClick}><a>See Credentials</a></button>
                    </div>
                    <div class="orbit">
                        <div class="rotating-item">
                            <div class="sun"></div>
                            <div class="ball"></div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
     );
}
 
export default Google;