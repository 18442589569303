import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import linkedin from '../images/linkedin.png'
import github from '../images/github.png'
import instagram from '../images/instagram.png'
import dribbble from '../images/dribbble.png'
import mail from '../images/mail.png'
import address from '../images/address.png'
import phone from '../images/phone.png'

const Contact = () => {
    const { pathname } = useLocation();

    const navigateToGithub = () => {
        window.open("https://github.com/Alix144", '_blank');
    }
    const navigateToLinkedin = () => {
        window.open("https://linkedin.com/in/ali-youssef-profile", '_blank');
    }
    const navigateToInstagram = () => {
        window.open("https://instagram.com/aliyoussef404", '_blank');
    }
    const navigateToDribbbles = () => {
        window.open("https://dribbble.com/AliX144", '_blank');
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return ( 
        <main className='contact-page'>
            <h1>Contact Me</h1>
            <section className="contact">
                <div className='contact-info'>
                    <div className="img">
                        <img src={mail} alt="Mail" />
                        <div className="nova5"></div>
                    </div>
                    <p>aliifarajj@hotmail.com</p>
                </div>
                <div className='contact-info'>
                    <div className="img">
                        <img src={phone} alt="Phone" />
                        <div className="nova5"></div>
                    </div>
                    <p>+965 69954663</p>
                </div>
                <div className='contact-info'>
                    <div className="img">
                        <img src={address} alt="Address" />
                        <div className="nova5"></div>
                    </div>
                    <p>Kuwait, Hawally</p>
                </div>
                <div className='contact-info-social-media'>
                    <div className="img">
                        <img src={linkedin} alt="Linkedin-logo" onClick={navigateToLinkedin}/>
                        <div className="nova5"></div>
                    </div>
                    <div className="img">
                        <img src={github} alt="Github-logo" onClick={navigateToGithub}/>
                        <div className="nova5"></div>
                    </div>
                    <div className="img">
                        <img src={instagram} alt="Instagram-logo" onClick={navigateToInstagram}/>
                        <div className="nova5"></div>
                    </div>
                    <div className="img">
                        <img src={dribbble} alt="Dribbble-logo" onClick={navigateToDribbbles}/>
                        <div className="nova5"></div>
                    </div>
                </div>

                <div className="nova6">
                    <div style={{backgroundColor: "#FF00D4"}}></div>
                    <div style={{backgroundColor: "purple"}}></div>
                    <div style={{backgroundColor: "blue"}}></div>
                    <div style={{backgroundColor: "#8351D4"}}></div>
                </div>
            </section>
        </main>
     );
}
 
export default Contact;