import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { setToNull, setToHome, setToAbout, setToFreelance, setToContact } from '../store/slices/currentPage.js';
import { setToClose, setToOpen } from '../store/slices/sidebar.js';

import logo from '../images/logo.png'
import hamburger from '../images/hamburger.png'

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const currentPage = useSelector((state) => state.currentPage.value)

    const navigateToHome = () => {
        navigate("/")
        dispatch(setToHome())
    }
    const navigateToAbout = () => {
        navigate("/about")
        dispatch(setToAbout())
    }
    const navigateToFreelance = () => {
        navigate("/freelance")
        dispatch(setToFreelance())
    }
    const navigateToContact = () => {
        navigate("/contact")
        dispatch(setToContact())
    }
    const handleDownload = () => {
        window.open(`${process.env.PUBLIC_URL}/Ali-Youssef-CV.pdf`, '_blank');
    };

    const openSidebar = () => {
        dispatch(setToOpen())
    }

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return ( 
        <header className={scrolled ? 'scrolled' : ''}>
            <div className="nova3"></div>
            <img src={logo} alt="Logo" onClick={navigateToHome}/>
            <img src={hamburger} alt="Hamburger button" className='hamburger' onClick={openSidebar}/>
            <nav>
                <ul>
                    <li className={`${currentPage === "home" && "current-page"}`} onClick={navigateToHome}>Home</li>
                    <li className={`${currentPage === "about" && "current-page"}`} onClick={navigateToAbout}>About</li>
                    <li className={`${currentPage === "freelance" && "current-page"}`} onClick={navigateToFreelance}>Freelance Work</li>
                    <li className={`${currentPage === "contact" && "current-page"}`} onClick={navigateToContact}>Contact</li>
                    <li onClick={handleDownload}>Resume</li>
                </ul>
            </nav>
        </header>
     );
}

export default Header;