import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import logo from '../images/logo1.png'
import prototype from '../images/prototype1.png'
import triangle from '../images/triangle.png'

import figma from '../images/figma.png'
import react from '../images/react.png'
import node from '../images/node.png'
import express from '../images/express.png'
import mongodb from '../images/mongodb.png'
import planning from '../images/plan.png'
import design from '../images/design.png'
import coding from '../images/coding.png'

import wireframe1 from '../images/wireframe1.png'
import wireframe2 from '../images/wireframe2.png'
import wireframe3 from '../images/wireframe3.png'
import wireframe4 from '../images/wireframe4.png'
import wireframe5 from '../images/wireframe5.png'
import wireframe6 from '../images/wireframe6.png'
import wireframe7 from '../images/wireframe7.png'
import wireframe8 from '../images/wireframe8.png'
import wireframe9 from '../images/wireframe9.png'

import prototype1 from '../images/prototype2.png'
import prototype2 from '../images/prototype3.png'
import prototype3 from '../images/prototype4.png'


const Class101 = () => {
    const { pathname } = useLocation();

    const navigateToPrototype = () => {
        window.open("https://www.figma.com/design/qW6cLIjOnGQ9DWKA9YKVGU/CLASS101-Prototype?node-id=0-1&t=v0BFTxmorz7iefTB-1", '_blank');
    }

    const navigateToSite = () => {
        window.open("https://class101.onrender.com", '_blank');
    }

    const navigateToSourcecode = () => {
        window.open("https://github.com/Alix144/class101.git", '_blank');
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return ( 
        <main className='class101'>
            <div className="scroll-watcher"></div>
            <div className="app-logo">
                <figure>
                    <img src={logo} alt="Class101-logo" />
                    <div className="light glow13"></div>
                </figure>
                <h1>CLASS101</h1>
            </div>
            <img src={prototype} alt="application-prototype" className='prototype'/>

            <section className="app-introduction">
                <div className="overview">
                    <h2>Overview</h2>
                    <div>
                        <h3>Project Type:</h3>
                        <p>Graduation Project (Solo)</p>
                    </div>
                    <div>
                        <h3>Timeline:</h3>
                        <p>2 Studying Semesters</p>
                    </div>
                    <div>
                        <h3>Tools Used:</h3>
                        <div className="tools-icons">
                            <img src={figma} alt="Figma" />
                            <img src={react} alt="ReactJS" />
                            <img src={node} alt="NodeJS" />
                            <img src={express} alt="ExpressJS" />
                            <img src={mongodb} alt="MongoDB" />
                        </div>
                    </div>
                    <div className="btns">
                        <button className="btn primary-btn" onClick={navigateToSite}><a>Visit Website</a></button>
                        <button className="btn secondary-btn" onClick={navigateToSourcecode}><a>Source Code</a></button>
                    </div>
                </div>
                <div className="details">
                    <div className="introduction">
                        <h2>Introduction</h2>
                        <p>The motivation behind developing the Learning Management System (LMS) presented in this thesis stems from a desire to enhance the efficiency, accessibility, and communication within traditional classroom education. The conventional approach to education often involves significant time and resource consumption, particularly in the distribution and management of documents, assignments, and announcements. This project seeks to address these challenges by leveraging technology to streamline educational processes and minimize the reliance on hard copies of resources.</p>
                    </div>

                    <div className="problem">
                        <h2>Problem</h2>
                        <p>The traditional approach to education typically requires considerable time and resources, especially when it comes to distributing and managing documents, assignments, and announcements.</p>
                    </div>

                    <div className="solution">
                        <h2>Solution</h2>
                        <p>Developing a Learning Management System (LMS) web application "Class101" that aims to:</p>
                        <ul>
                            <li>Reduce paper consumption within educational settings.</li>
                            <li>Improve communication and collaboration among class members.</li>
                            <li>Reducing wasted time associated with manual document handling, retrieval, and distribution processes in educational environments.</li>
                        </ul>
                    </div>

                    <div className="features">
                        <h2>Key Features</h2>
                        <ul>
                            <li>Create & join Classes.</li>
                            <li>To do list.</li>
                            <li>Edit profile information.</li>
                            <li>Share / view announcements - syllabus - documents - assignments.</li>
                            <li>Group chat for class members.</li>
                            <li>Q&As section.</li>
                            <li>User Authentication.</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="process">
                <h2>The Process</h2>
                <div className="phases">
                    <div className="phase">
                        <img src={planning} alt="planning-phase" />
                        <caption>Planning & Analysis</caption>
                    </div>
                    <hr />
                    <div className="phase">
                        <img src={design} alt="design-phase" />
                        <caption>Design</caption>
                    </div>
                    <hr />
                    <div className="phase">
                        <img src={coding} alt="coding-phase" />
                        <caption>Coding</caption>
                    </div>
                </div>
                <div className="line line-1"></div>
                <div className="nova6">
                    <div style={{backgroundColor: "#FF00D4"}}></div>
                    <div style={{backgroundColor: "purple"}}></div>
                    <div style={{backgroundColor: "blue"}}></div>
                    <div style={{backgroundColor: "#8351D4"}}></div>
                </div>
            </section>

            <section className="planning">
                <img src={triangle} alt="Triangle-stars" />
                <h2><span className='numbers'>1.</span>Planning & Analysis</h2>
                <p>Clarifying the concept, brainstorming ideas, defining objectives, and setting time  limits in this phase were crucial for ensuring a focused and successful future for the  project. Below are the steps that were conducted to insure a solid foundation:</p>
                <div>
                    <h3>Project idea:</h3>
                    <p>To find an idea that can help people, I overviewed the problems I personally faced and tried to find one that can be solved with an online application. One problem is the unorganized planning of education, lack of communication and overuse of printed resources for documents in traditional classroom setting. A solution for this was developing an LMS system named Class101 that seeks to solve these challenges.</p>
                </div>
                <div>
                    <h3>Brainstorming and Ideation:</h3>
                    <p>Next, I did a small research on what features users would like to see in an LMS  system. One method was searching for similar applications like Google  Classroom and Microsoft Teams. The features included in these apps were  beneficial and crucial to add to my system. In addition to that, I came up with more features and functionalities by brainstorming.</p>
                </div>
                <div>
                    <h3>Defining Objectives and setting time limits:</h3>
                    <p>After coming up with many solutions and features, I proposed some of them to  the stakeholder (Instructor) and gained feedback. Following that I conducted a thorough analysis to prioritize the most impactful features for the  project based on the feedback. Then, I set the duration of the next phases to  insure an organized path. Also in this step I selected all the tools needed to develop the application.</p>
                </div>
                <div>
                    <h3>Learning</h3>
                    <p>Knowing the features and the tools that I’m going to use, I needed to learn how to use some libraries. For that, I followed multiple tutorials and courses in platforms like Udemy, Youtube, and Coursera that gave me the enough knowledge to build such an app.</p>
                </div>
            </section>

            <section className="design">
                <h2><span className='numbers'>2.</span>Design</h2>
                <p>In the design phase, I translated the refined objectives and prioritized features into tangible visual and architectural blueprints for the application. By incorporating feedback from the stakeholder and usability testing, I refine the design iteratively.</p>
                <div>
                    <h3>User Flow</h3>
                    <p>The first step in this phase was to define the user flow. I outlined the user flow before  starting to design because it can help to picture how users will move through the app or website.</p>
                </div>
                <div>
                    <h3>Wireframes</h3>
                    <p>Basic wireframes drawings were created to visualize and reduce products down to their basic structures and highlight each element’s intended function</p>
                    <div className="wireframes">
                        <img src={wireframe1} alt="Wireframe"/>
                        <img src={wireframe2} alt="Wireframe"/>
                        <img src={wireframe3} alt="Wireframe"/>
                        <img src={wireframe4} alt="Wireframe"/>
                        <img src={wireframe5} alt="Wireframe"/>
                        <img src={wireframe6} alt="Wireframe"/>
                        <img src={wireframe7} alt="Wireframe"/>
                        <img src={wireframe8} alt="Wireframe"/>
                        <img src={wireframe9} alt="Wireframe"/>
                    </div>
                </div>
                <div>
                    <h3>High-Fidelity Prototype</h3>
                    <p>The high-fidelity prototype represents a significant advancement from the wireframes, providing a detailed and visually polished rendition of the user interface using Figma.</p>
                    <div className="prototypes">
                        <img src={prototype1} alt="Prototype" />
                        <img src={prototype2} alt="Prototype" />
                        <img src={prototype3} alt="Prototype" />
                    </div>
                    <div className='btns'>
                        <button className="btn primary-btn" onClick={navigateToPrototype}><div className="glow"></div><img src={figma} alt="Figma" /><a>View in Figma</a></button>
                    </div>
                </div>
            </section>

            <section className="coding">
                <div className="line line-1"></div>
                <h2><span className='numbers'>3.</span>Coding</h2>
                <div>
                    <h3>Frontend Development</h3>
                    <p>In the frontend development the project, I've leveraged a powerful set of tools to  enhance the user interface and functionality. React JS forms the foundation, providing a dynamic and component-based structure. With React JS, I used HTML and CSS for  structure and styling.</p>
                </div>
                <div>
                    <h3>Backend Development</h3>
                    <p>In the backend implementation, the focus is on creating a robust and scalable  server-side infrastructure using Node.js, Express.js, and MongoDB. This phase  involves setting up the server, defining API endpoints, managing the database, and  ensuring secure and efficient communication between the client and server.</p>
                </div>
            </section>

            <section className="enhancement">
                <div className="line line-2"></div>
                <h2>Future Enhancements</h2>
                <div>
                    <h3>Video Conferencing</h3>
                    <p>By including this feature, Class101 can offer a more comprehensive  virtual learning experience.</p>
                </div>
                <div>
                    <h3>Share Polls</h3>
                    <p>This feature would enable instructors to create and distribute polls to gather instant feedback, assess  understanding of the material, or gauge opinions on various topics. Polls  can be used during live sessions to make classes more interactive.</p>
                </div>
                <div>
                    <h3>Integration With Third-Party Tools</h3>
                    <p>Integrating Class101 with popular third-party educational tools and  platforms could further enrich the learning experience</p>
                </div>
            </section>

            <div className='visit-app'>
                <div className="app-logo">
                    <figure>
                        <img src={logo} alt="Class101-logo" />
                        <div className="light glow13"></div>
                    </figure>
                    <h1>CLASS101</h1>
                </div>
                <button className="btn primary-btn" onClick={navigateToSite}><a>Visit</a></button>
                <div className="nova4"></div>
            </div>

        </main>
     );
}
 
export default Class101;